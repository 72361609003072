var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive } ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'KF Global Hall', icon: 'public', path: '/edit/lobby' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'KF Meta Gallery', icon: 'collections', path: '/edit/gallery'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'KF Conference Hall', icon: 'campaign', path: '/conference'}}}),_c('sidebar-item',{attrs:{"link":{
          name: 'KF Link',
          icon: 'link',
          path: '/edit/external-link',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Quest',
          icon: 'content_paste',
          path: '/quest',
        }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Account', icon: 'account_circle', path: '/account'}}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('div',{class:{ content: !_vm.$route.meta.hideContent }},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }